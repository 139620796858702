/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import PayCard from "./payCard";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
	updateKeyShop,
	editShops,
	updateSettings,
	updatePayCard,
} from "../../reducers/shops";
import { withFirebase } from "../../firebase";

const PayCardContainer = ({
	updateKeyShop,
	editShops,
	updateSettings,
	updatePayCard,
	firebase,
	shops,
	closedAlert,
}) => {
	const { city } = useParams();
	const [checked, setChecked] = useState(false);
	const [loadStatus, setLoadStatus] = useState(false);
	const [cityData, setCityData] = useState([]);

	const [statusPayment, setStatusPayment] = useState(false);

	const cityIndex = localStorage.getItem("chosenCity");
	const adminPermission = localStorage.getItem("adminPermission");
	const brand = localStorage.getItem("chosenBrand");

	const ref = JSON.parse(adminPermission).newAdmin
		? `web/settings/city/${cityIndex}/brands/${brand}`
		: `web/settings/city/${cityIndex}`;

	const location = useLocation();

	useEffect(() => {
		getStatus();
	}, [location]);

	const getStatus = () => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		firebase.bd
			.ref(`${isDebug ? "debug" : ""}/${ref}/checkoutScreen/isCardAvailable`)
			.on("value", (snapshot) => {
				let res = snapshot.val();

				console.log("result", res);
				setStatusPayment(res);
			});
	};

	const updateStatusApp = () => {
		firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/${ref}/checkoutScreen`
			)
			.update({ isCardAvailable: !statusPayment })
			.then(() => {
				getStatus();
			})
			.catch((error) => {
				console.error("Failed to update statusPayment:", error);
			});
	};

	// const updateCity = (updateData) => {
	// 	const names = Object.keys(updateData);

	// 	const data = {
	// 		id: city,
	// 		list: updateData,
	// 		names,
	// 	};

	// 	updatePayCard(data);
	// 	setCityData(data.list);
	// };

	// const sendData = (data) => {
	// 	const updateData = {};
	// 	const dataNames = ["fondy", "liqPay", "wayForPay", "yandex", "portmone"];
	// 	const cityPaymentSystems =
	// 		shops[cityIndex]?.brands[brand].cardPaymentSystem;

	// 	dataNames.forEach((name) => {
	// 		if (cityPaymentSystems?.[name]) {
	// 			updateData[name] = data;
	// 		}
	// 	});

	// 	setLoadStatus(true);
	// 	firebase.bd
	// 		.ref(
	// 			`${
	// 				JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
	// 			}/settings/city/${cityIndex}/brands/${brand}/cardPaymentSystem`
	// 		)
	// 		.update(updateData)
	// 		.then(() => {
	// 			// setLoadStatus(false);
	// 			// updateCity(updateData);
	// 		});
	// };

	const setShop = JSON.parse(adminPermission).newAdmin
		? shops[cityIndex]?.brands[brand]
		: shops[cityIndex];

	return (
		<>
			<PayCard
				updateStatusApp={updateStatusApp}
				statusPayment={statusPayment}
				// sendData={sendData}
				loadStatus={loadStatus}
				// keyShop={city}
				closedAlert={closedAlert}
				shops={setShop}
				city={cityData}
				editShops={editShops}
				checked={checked}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	shops: state.shops.shops,
	appSettings: state.shops.appSettings,
});

export default connect(mapStateToProps, {
	updateKeyShop,
	editShops,
	updateSettings,
	updatePayCard,
})(withFirebase(PayCardContainer));
